/* xl max width */
@media only screen and (max-width: 1200px) {

    #row-header-title{
        height: 90%
    }
    .donate-decoration {
        padding: 11em 0;
    }
    .event-decoration {
        padding-bottom: 8em;
    }
}

/* lg max width */
@media only screen and (max-width: 992px) {
    .homeHeader{
        height:100vh;
    }
    .event-decoration {
        padding-top: 6em;
    }

    .donate-decoration {
        padding: 10em 0;
    }
}

/* md max width */
@media only screen and (max-width: 768px) {
    #img-theme{
        width: 70%
    }
    
    #miela-card{
        width: 75%
    }
    .event-decoration {
        padding-top: 5em;
        padding-bottom: 5em;
    }

    .donate-decoration {
        padding: 8em 0;
    }
}

/* sm max width */
@media only screen and (max-width: 576px) {
    .img-sponsor{
        width: 70%
    }
    
    #miela-card{
        width: 100%
    }

    .event-decoration {
        padding-top: 3em;
    }

    .donate-decoration {
        padding: 5em 0;
    }
}

/* custom max width */

@media only screen and (max-width: 500px) {
    .miela-div{
        width:60%
    }
    #row-header-title h2{
        font-size: 1.5rem
    }
    #homeHeader-div{
        height:90%
    }
}

@media only screen and (max-width: 400px) {
    .miela-div{
        width:85%
    }
    #img-theme{
        width: 75%
    }
}

@media only screen and (max-width: 300px) {
    #row-header-title h2{
        font-size: 1.3rem;
    }

    #campaign-btn-text{
        font-size: 1.1rem
    }
}

@media only screen and (max-height: 470px) {
    #row-header-title h2{
        font-size: 1.3rem;
    }

    #campaign-btn-text{
        font-size: 1.1rem
    }
}

@media only screen and (max-width: 767px) {
    .dataTables_filter, .dataTables_length {
        padding-bottom: 10px;
    }
    .dataTables_filter, .dataTables_length, .dataTables_info {
        float: left !important;
        padding-left: 0;
        margin: 0;
    }
    .dataTables_info{
        margin-bottom: 5px;
    }
    .dataTables_paginate{
        padding: 10px 0;
    }
    .dataTables_info, .dataTables_length{
        margin-left: 13px;
    }
}
