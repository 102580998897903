// Body
$body-bg: #FFFF;

// Typography
$font-family-sans-serif: -apple-system, BlinkMacSystemFont,"Nunito", "Nunito Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-weight: 400;
$font-size-base: 1rem;
$line-height-base: 1.5;

// Colors
$blue: #37A3F4;
$black: #231F20;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #F43737;
$orange: #F58634;
$yellow: #FFCC29;
$green: #44CC98;
$teal: #20c997;
$cyan: #0FC1FC;
