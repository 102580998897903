h1, h2, h3 {
    font-family: 'Nunito Sans', sans-serif;
}
.rounded-20 {
    border-radius: 20px !important;
}

.rounded-50 {
    border-radius: 50px !important;
}

.shadow-smt-sm {
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
}

.shadow-smt-md {
    box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
}

.shadow-smt-xl  {
    box-shadow: 31px 17px 45px 0 #8989894d;
}

.text-secondary {
    color: #1E6F5C !important;
}

.text-orange {
    color: $orange;
}

.text-green {
    color: #319E7A;
}

.card-patterned {
    background: url(/storage/images/assets/pattern.png) center center repeat;
    background-size: 100%, 100%;
    border-radius: 20px !important;
    border: 0 !important;
}

.card-textured {
    background: url(/storage/images/assets/texture-bg.webp) center center repeat;
    background-size: 100%, 100%;
    border-radius: 20px !important;
    box-shadow: 31px 17px 45px 0 #8989894d;
    border: 0 !important;
}

.btn-outline-green {
    border: 1px solid #009798;
    color: #1E6F5C;
    transition: .2s ease;
}

.btn-outline-green:hover {
    background-color: #009798;
    color: #FFFF;
}

.divider {
    width: 80px; 
    height: 3px;
    background-image:linear-gradient(90deg,#F58634 ,#FFCC29) !important;
    border-radius: 50px;
    margin-bottom: 1rem;
}

.divider-green {
    width: 80px; 
    height: 3px;
    background-image:linear-gradient(90deg,#00baa5 ,#0098D1) !important;
    border-radius: 50px;
    margin-bottom: 1rem;
}

.bg-orange-gradient {
    background-image:linear-gradient(90deg,#F58634 ,#FFCC29) !important;
}
.card-header {
    padding: .75rem 1.25rem;;
    background-color: #009798;
    border: none;
    border-radius: 20px 20px 0 0 !important;
}

.text-orange-gradient {
    background-image: linear-gradient(#F58634 50%,#FFCC29) !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient {
    background: -webkit-linear-gradient(105deg, #268168, #44CC98);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.btn-login-gradient {
    border: 0px;
    background: -webkit-linear-gradient(120deg, #268168, #44CC98);
    font-weight: 500;
    transition: 0.5s ease;
    border-radius: 5px;
}

.btn-login-gradient:hover {
    border-radius: 30px;
}

.btn-transparent {
    border: 1px solid #009798;
    color: #1E6F5C;
    transition: .3s ease;
}

.btn-transparent:hover{
    transform: scale(1.07);
    color: #1E6F5C;
}

.btn-green-gradient {
    border: 0px;
    background-size: 200% auto;
    color: white;
    background-image: linear-gradient(to right, #2a8f74 0%, #5fdaaa 50%, #2a8f74 100%);
    font-weight: 500;
    transition: 0.7s ;
}

.btn-green-gradient:hover {
     background-position: right center;
}

.title-line {
    background-color: $yellow;
}

.section-line {
    width: 80px; 
    height: 3px; 
    margin:auto; 
    border: 20px;
    background-color: $yellow;
}

.btn-orange {
    background-color: $orange;
}

.btn-orange:hover {
    background-color: #FD9843;
}

.bg-orange {
    background-color: $orange;
}

.btn-block {
      display: block;
      width: 100%;
}

.nav-pills .nav-link.active {
    background-color: #00BAA5;
    color: white;
}

.nav-pills .nav-link {
    background-color: transparent;
    color: #00BAA5;
    border: 1.2px solid #00BAA5;
    transition : 0.3s;
}

.nav-pills .nav-link:hover {
    background-color: #00BAA5;
    color: white;
}

.table-clickable {
    cursor: pointer;
}

.btn-outline-success:hover {
    color: #ffff;
}

.bg-dark-gradient{
    background-image:linear-gradient(115deg, #258D75, #44CC98 100%); 
}

.sidebar-menu::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}

.sidebar-menu::-webkit-scrollbar-thumb {
    border-radius: 1.5px;
    background-color: #384B42;
}

.callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
  border-radius: 6px;
}

.callout h4 {
  margin-top: 0;
}

.callout p:last-child {
  margin-bottom: 0;
}

.callout code,
.callout .highlight {
  background-color: #fff;
}

.callout-info {
    border-color: #009798;
}

.callout-warning {
    border-color: #FFCC29;
}