 /* -------------------- NAVBAR ----------------------*/
 .nav-btn {
  border-radius: 40px;
  transition: .3s ease-in-out;
}

.nav-btn:hover {
  background: #00BAA5;
  color: white;
}

.dropdown-item:active {
    background-color: #44CC97;
    color: white !important;
}

.btn-donate {
  transition: .2s ease;
  background: #44CC97;
}

.btn-donate:hover {
  box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
}

@media only screen and (max-width: 1230px) {
  .navbar-container{
      width: 93%;
  }
}
@media only screen and (max-width: 992px) {
  #logo{
      width: 140px;
  }
  
}
@media only screen and (max-width: 576px) {
  #logo{
      width: 115px;
  }

  .navbar-toggler-icon{
      font-size: 1rem;
  }
  .navbar-container{
      width: 96%;
  }
}

/* -------------------- FOOTER ----------------------*/
.footer {
    background-color: #343A40;
    background-image: url('/storage/images/assets/food-pattern.webp');
    background-size: 100%, cover;
    color: white;
    border-radius: 100px 0 0 0;
}
.me-4 a {
    transition: .5s ease;
    color: white;
}
.me-4 a:hover {
    color: #44CC98;
}

.footer-img{
    width: 100%
}

@media only screen and (max-width: 992px) {
    .footer-img{
        width: 65%
    }
}

@media only screen and (max-width: 768px) {
    .footer-img{
        width: 35%
    }
}

@media only screen and (max-width: 600px) {
    .text-img-footer{
        font-size: 1.1rem;
    }
}

@media only screen and (max-width: 420px) {
    .text-img-footer{
        font-size: 0.9rem;
    }
}