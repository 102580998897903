/* DASHBOARD ADMIN */
.rounded-3 {
    background-color: #fff;
    transition: 0.5s;
    border-radius: 20px !important;
}

.rounded-3:hover {
    background-color: #eaeaea;
}

.container {
    max-width: 1140px;
}

.text-article {
    color: #384B42;
}

/* ----- SCROLL TO TOP BUTTON ----- */
#scrollUp {
    position: fixed;
    right: 1.3rem;
    bottom: 1rem;
    z-index: 99;
    border: none !important;
    outline: none !important;
    border-radius: 50px;
    background-color: #F58634;
    color: #FFF;
    cursor: pointer;
    padding: 1.1rem 1.2rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease;
}
#scrollUp:hover {
    background-color: #FBAE2E;
}


/* ----- HOME PAGE ----- */
/* Typing Text */
.themeText {
    font-size: clamp(1rem, 1vw + 1rem, 3rem);
    position: relative;
  	overflow: hidden;
    white-space: nowrap;
 	animation: typing 3s steps(15, end), blink 750ms step-end infinite;
    border-right: 4px solid $orange;
    width: max-content;
}

.btn-campaign-animation {
    opacity: 0;
    transform: translateY(3rem);
    transition: .3s ease;
    animation: fadeInUp 1s ease 3s forwards;  
}

@keyframes typing {
    from { 
        width: 0% 
    }
    to { 
        width: 50%
    }
}

@keyframes blink {
  from, to { 
        border-color: transparent 
    }
    50% { 
        border-color: $orange; 
    }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn-campaign:hover {
    transform: scale(1.07);
}

.donate-decoration {
    background: url("/storage/images/assets/divider-up-white.webp") no-repeat center top,
        url("/storage/images/assets/divider-down-white.webp") no-repeat center bottom,
        url("/storage/images/assets/food-pattern.webp") repeat center center;
    background-color: #F8F9FA;
    padding: 14em 0;
    background-size: 100%, 100%;
}

.event-decoration {
    background: url("/storage/images/assets/divider-up-white.webp") no-repeat center top,
        url("/storage/images/assets/divider-down-white.webp") no-repeat center bottom,
        url("/storage/images/assets/food-pattern.webp") repeat center center;
    background-color: #F8F9FA;
    padding-top: 9em;
    padding-bottom: 12em;
    background-size: 100%, 100%;
}

/* Landing Home Background Gradient */
.homeHeader {
    height: 120vh;
    min-height: 500px;
    background-color: #44cc98;
    background-image: linear-gradient(160deg, #44cc98 0%, #1e6f5c 59%, #0b2a23 100%);
    position: relative;
}
.homeHeader .waves {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}

#container-header{
    height: 70%
}
#row-header-title{
    height: 60%
}

@media only screen and (max-height: 1170px) {
    #donation-desc{
        padding-left: 3em;
    }
}

@media only screen and (max-height: 768px) {
    #donation-desc{
        padding-left: 0;
        padding: 0 2em;
    }
}

/* BNEC Section Assets */
.btn-bnec {
    padding-right: 1rem;
    padding-left: 1rem;
    background-color: #3A3A3C;
    box-shadow: inset 0 0 0 0 #1db9a1;
    transition: 0.3s ease-out;
    border: none;
}
.btn-bnec:hover {
    box-shadow: inset 300px 0 0 0 #1db9a1;
    outline: none;
}
.text-orange {
    color: #F6841F;
    font-weight: 600;
}

/* CUSTOM SCROLLBAR PAGE */
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #3F4A3C;
}

/* NAVBAR HOME */
.scrolled {
    background: white !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    filter: brightness(1) invert(0);
}

/* PAGINATION STYLES */
.page-item.active .page-link {
    background-color: #00BAA5 !important;
    color: #FFF;
}
.page-link {
    color: #00BAA5;
}

/* ARTICLE PAGE - GUEST */
.content-post {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden
}

.content-post .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s
}

.content-post:hover .content-overlay {
    opacity: 1
}

.overlay-content {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s
}

.content-post:hover .overlay-content {
    top: 50%;
    left: 50%;
    opacity: 1
 } 

.fadeIn-bottom {
    top: 80%
}

/* IMAGE EXPANDED - GUEST */
.img-expand {
    transition: .3s ease;
}
.img-expand:hover {
    transform: scale(1.07);
}

//container
.container {
    max-width : 1140px;
}